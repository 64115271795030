export default {
  proxy_path: "https://evnydmd7nb.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://aswim6xw91.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyD84bsWJslpBGrsiinmyTx5aLPpbXFz0a8",
  main_company_term_id: "6",
  crm_base_url: "https://crm.staging.saas.forwoodsafety.com",
  forwood_id_url: "https://id.staging.saas.forwoodsafety.com?redirect_uri=https://mapreport.staging.saas.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.saas.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_fReqbdQKG",
    userPoolWebClientId: "4v4g1458bdr08eod09qsi0vdvh",
    cookieStorage: {
      domain: ".staging.saas.forwoodsafety.com",
      secure: true
    }
  }
};
